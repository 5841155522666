import React from "react";
import "./SolutionCard.css";

function SolutionCard({ title, subtitle: content, image }) {
  return (
    <div className="solution-card">
      <div className="solution-content-card">
        <div className="solution-card-title">{title}</div>
        <div className="solution-card-content">{content}</div>
      </div>
      <div className="solution-card-image">
        <img src={image} alt=""/>
      </div>
    </div>
  );
}

export default SolutionCard;
