import React from 'react'
import './FeatureCard.css'

function FeatureCard({title,content,colorId}) {
    return(
        <div className='feature-card'> 
            <div className='feature-background' id={colorId}></div>
            <div className='feature-card-body'>
                <div className='feature-title'>{title}</div>
                <div className='feature-content'>{content}</div>
            </div>
        </div>
    )
}

export default FeatureCard