import React from 'react'
import './CommonHeader.css'

function CommonHeder({title,subtitle,colorId}) {
    return(
        <div className='header-card'> 
            <div className='header-background' id={colorId}></div>
            <div className='header-card-body'>
                <div className='header-title'>{title}</div>
                <div className='header-subtitle'>{subtitle}</div>
            </div>
        </div>
    )
}

export default CommonHeder