import React from "react";
import Navbar from "../Navbar/Navbar";
import FeatureCard from "../../Components/FeartureCard/FeatureCard";
import Background from "../../Assets/home-background.svg";
import "./Home.css";
import ImgStudent from "../../Assets/student.png";
import ImgWebDevelopment from "../../Assets/img-web-solution.png";
import ImgMobileDevelopment from "../../Assets/img-mobile-solution.png";
import ImgHeadLine from "../../Assets/head_line.png";
import ImgHeadArrow from "../../Assets/head_arrow.png";
import ImgDotIndicator from "../../Assets/dot_indicator.png";
import ImgSolarSystem from "../../Assets/solar_system.png";
import CommonHeder from "../../Components/CommonHeader/CommonHeader";
import SolutionCard from "../../Components/SolutionCard/SolutionCard";
import StyledTextTile from "../../Components/StyledTextStyle/StyledTextTile";

function Home() {
  return (
    <div>
      <Navbar />
      <div className="home-banner">
        <div className="home-banner-background">
          <img src={Background} alt="Background" />
        </div>
        <div className="home-banner-content-container">
          <img src={ImgHeadLine} alt="" />
          <h1 className="home-banner-heading">
            Where{" "}
            <span style={{ color: "#20CFE7", fontFamily: "Inter" }}>dream</span>{" "}
            <br />
            <span style={{ color: "#EF7940", fontFamily: "Inter" }}>
              develops
            </span>{" "}
            the future
          </h1>
          <p className="home-banner-text">
            Visionary ideas into concrete technological innovations that propel
            us into a more advanced and promising future
          </p>
          <button className="btn-secondary">Contact Us</button>
        </div>
        <div className="home-banner-graphics-container">
          <img className="solar-system" src={ImgSolarSystem} alt="" />
          <img className="student-image" src={ImgStudent} alt="" />
          <img className="arrow-image" src={ImgHeadArrow} alt="" />
          <img className="dot-indicator" src={ImgDotIndicator} alt="" />
        </div>
      </div>
      <div className="features">
        <FeatureCard
          title={
            <>
              <span>Boost efficiency</span> <br /> <span>and productivity</span>
            </>
          }
          content="Leverage our skilled software team for rapid testing, development, and scaling. Craft exceptional digital solutions tailored to your business needs."
          colorId="blue"
        ></FeatureCard>
        <FeatureCard
          title={
            <>
              <span>Turning Technology</span>
              <br />
              <span>into Profit</span>
            </>
          }
          content="At Vexellab, we bridge the divide between cutting-edge tech and profitability, striving to convert innovation into highly profitable results."
          colorId="red"
        ></FeatureCard>
        <FeatureCard
          title={
            <>
              <span>Accelerate Your</span>
              <br />
              <span>Market Entry</span>
            </>
          }
          content="Vexellab is dedicated to crafting custom, market-ready solutions that enable you to outpace competitors and deliver value swiftly."
          colorId="orange"
        ></FeatureCard>
      </div>
      <div className="solutions-container">
        <CommonHeder
          title="Our Solutions"
          subtitle="We provide a wide array of solutions, each carefully crafted to help your business discover a fresh competitive advantage"
          colorId="purple"
        ></CommonHeder>
        <div className="solutions-content-container">
          <SolutionCard
            title={
              <>
                <span>Web App</span> <span>Development</span>
              </>
            }
            image={ImgWebDevelopment}
            subtitle="Enhance Customer Relationships and Drive Exponential Growth with a Tailor-Made Web Application that Aligns with Your Business Objectives and Requirements."
          ></SolutionCard>
          <SolutionCard
            title={
              <>
                <span>Mobile App</span>
                <span>Development</span>
              </>
            }
            image={ImgMobileDevelopment}
            subtitle="Put your business in the hands of customers with an outstanding mobile experience. Boost brand awareness, customer loyalty, and revenue with our leading mobile development services."
          ></SolutionCard>
        </div>
      </div>
      <div className="technologies-container">
        <CommonHeder
          title="Our Technologies"
          subtitle="We possess deep expertise in developing web and mobile applications, leveraging the latest technology trends to create highly efficient software solutions"
          colorId="orange"
        ></CommonHeder>
        <div className="technologies-content-container">
            <StyledTextTile text="MERN Stack" color="orange"/>
            <StyledTextTile text="Django" color="orange"/>
            <StyledTextTile text="Flutter" color="orange"/>
            <StyledTextTile text="React" color="orange"/>
            <StyledTextTile text="React Native" color="orange"/>
            <StyledTextTile text="Kotlin" color="orange"/>
            <StyledTextTile text="SwiftUI & UIKit" color="orange"/>
        </div>
      </div>
    </div>
  );
}

export default Home;
