import React from 'react';
import './StyledTextTile.css';

const StyledTextTile = ({ text , color}) => {
  return (
    <div className="styled-text-container" >
      <div className="square-prefix" id={color} ></div>
      <div className="text-content" id={color}>{text}</div>
    </div>
  );
};

export default StyledTextTile;