import React, { useState } from 'react'
import Logo from '../../Assets/Logo.svg'
import './Navbar.css'
import { HiOutlineBars3 } from 'react-icons/hi2'
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material'

function Navbar() {
    const [openMenu, setOpenMenu] = useState(false)
    const menuOptions = [
        {
            text: "Home",
        },
        {
            text: "Careers",
        },
        {
            text: "Contact Us",
        },
    ];
    return (
        <nav>
            <div className='nav-logo'>
                <img src={Logo} alt='Logo' />
            </div>
            <div className='nav-items'>
                <div className='nav-links-container'>
                    {menuOptions.map((option) => (
                        <a href='/' className='nav-link'>{option.text}</a>
                    ))}
                </div>
                <button className='btn-primary'>
                    Get Qoute
                </button>
            </div>
            <div className='navbar-menu-container'>
                <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
            </div>
            <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor='right'>
                <Box sx={{ width: 250 }} role='presentation' onClick={() => setOpenMenu(false)} onKeyDown={() => setOpenMenu(false)}>
                    <List>
                        {menuOptions.map((item) => (
                            <ListItem key={item.text} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </nav>
    )
}

export default Navbar